<template>
  <ws-dialog
      v-model="display"
      @save="startNewsletter"
      width="458"
      no-padding
  >

    <div class="d-flex justify-center">
      <img
          src="@/modules/finemailer/assets/img/send_email.png"
          :style="`max-height: 168px`"
      >
    </div>
    <h5 class="text-center font-weight-regular mt-10">Цей момент настав, ви відправляєте:</h5>
    <h4 class="text-center shorten-text mx-auto" style="max-width: 350px">{{ newsletter.name }}</h4>

    <template #footer>
      <div class="d-flex justify-center py-7">
        <ws-button
            @click="display = false"
            label="mailer.send.cancel"
            class="mr-2"
            outlined
            :disabled="LOADING"
        />
        <ws-button
            @click="startNewsletter"
            label="mailer.send.confirm"
            :loading="LOADING"
            :disabled="LOADING"
        />
      </div>

    </template>

  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "startNewsletterDialog",
  props : {
    value : {
      type : Boolean
    },
    newsletter : {
      type : Object,
      default() { return {} }
    },
    noRedirect : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      display : false
    }
  },
  methods : {
    ...mapActions('finemailer' , [
      'START_NEWSLETTER'
    ]),

    async startNewsletter() {
      let result = await this.START_NEWSLETTER(this.newsletter.uuid)
      if (!result) {
        return this.ERROR(this.$store.state.ajax.error)
      }

      this.notify(this.$t(!this.newsletter.is_automation ? 'mailer.single.started' : 'mailer.automations.started') , 'success')



      this.$emit('success')
      if ( !this.noRedirect) {
        this.$router.push(this.businessDashLink(`fine_mailer/view/${this.newsletter.uuid}`))
      } else {
        this.display = false
      }

    },
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
  }
}
</script>

<style scoped>

</style>